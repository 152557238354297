<template>
  <v-chip
    :key="source"
    v-bind="$attrs"
    :outlined="outlined"
    label
    x-small
    class="py-3 pr-2"
  >
    <span
      class="mt-1"
      :class="name ? 'ms-n2 me-1' : 'me-n2'"
    >
      <source-icon
        :source="source"
        :no-tooltip="noTooltip"
        :size="14"
        left
      />
    </span>
    {{ text }}
  </v-chip>
</template>
<script>
  export default {
    components: {
      SourceIcon: () => import('@/components/source/SourceIcon.vue'),
    },
    props: {
      source: {
        type: String,
        default: null,
      },
      name: {
        type: String,
        required: true,
      },
      outlined: {
        type: Boolean,
        default: true,
      },
      noTooltip: Boolean,
    },
    computed: {
      text () {
        return this.$t(this.name)
      },
    },
  }
</script>
